import Vue from 'vue'

import {
  Carousel, CarouselItem,
  Drawer,
  Button, Select, Option, Menu, Submenu, MenuItem, Input, Cascader, Table, TableColumn, Dialog, Dropdown, DropdownMenu, DropdownItem, Steps, Step, Form, FormItem, DatePicker, Tag,CheckboxGroup, Checkbox, Upload,Message,Pagination,Loading,MessageBox, Popover
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

[ Carousel, CarouselItem,
  Drawer,
  Button, Select, Option, Menu, Submenu, MenuItem, Input, Cascader, Table, TableColumn, Dialog, Dropdown, DropdownMenu, DropdownItem, Steps, Step, Form, FormItem, DatePicker, Tag, CheckboxGroup, Checkbox, Upload,Pagination,Loading,Loading.directive,Popover].forEach(item => {
  Vue.use(item)
})