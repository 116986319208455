import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  asinCollectList: [],
}

export default {
  getters,
  state,
  actions,
  mutations,
}
