import Vue from 'vue'
import Vuex from 'vuex'
import { getItem,setItem } from '@/utils/storage.js'
import createPersistedState from 'vuex-persistedstate';
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getItem('token')
  },
  mutations: {
    ['SETTOKEN'](state,params) {
      params.endDate = +new Date() + params.expire * 1000
      state.token = params
      setItem('token',params)
    }
  },
  actions: {
  },
  modules: {
    user,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'AaDu-store',
      /* reducer(state) {
        return { ...state }
      }, */
      paths: ['user'], // 模块
    }),
  ], // 持久化store
})
