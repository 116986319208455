import { Message } from 'element-ui'
import { deepClone } from '@/utils/tool.js'

export default {
  addCart(state, row) {
    let { asinCollectList } = state
    let isHas = asinCollectList.some(el => el.goodId === row.goodId)
    if (isHas) {
      Message.warning('改商品已在购物车')
    } else {
      asinCollectList.unshift(deepClone(row))
    }
  },
  setAsinCollectList(state, data) {
    state.asinCollectList = data
  },
}
