export const debounce = (fn, time) => {
  let timeId = null;
  return function () {
    let context = this
    let args = arguments
    if (timeId) {
      clearTimeout(timeId)
      timeId = null
    }
    timeId = setTimeout(function () {
      fn.apply(context, args)
    }, time)
  }
}

export const throttle = (fn, time) => {
  let prev = 0;
  return function () {
    let context = this
    let args = arguments
    let now = Date.now()
    if (now - prev >= time) {
      setTimeout(function () {
        fn.apply(context, args)
        prev = now
      })
    }
  }
}

export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}
