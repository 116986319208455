import Vue from 'vue'
import VueRouter from 'vue-router'
import My from './modules/my'
import store from '@/store/'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '暗度',
      keepAlive: false
    }
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/views/my/index.vue'),
    redirect: '/user-info',
    children: My
  },
  {
    path: '/fqa',
    name: 'Fqa',
    component: () => import('@/views/fqa/index.vue')
  },
  {
    path: '/invite-activity',
    name: 'InviteActivity',
    component: () => import('@/views/activity/index.vue')
  },
  {
    path: '/appraise',
    name: 'Appraise',
    component: () => import('@/views/appraise/index.vue')
  },
  {
    path: '/kxx',
    name: 'Kxx',
    component: () => import('@/views/pckxx/index.vue'),
  },
  {
    path: '/regard',
    name: 'regard',
    component: () => import('@/views/regard/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})
// 解决多次点击跳转同一个路由报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = '暗度'
  }
  if (to.path !== '/home') {
    // 如果已登录，则直接通过
    if (store.state.token) {
      return next()
    } else {
      const noForceHomeArr = [
        '/fqa', '/appraise', '/kxx','/regard'
      ]
      if (!noForceHomeArr.includes(to.path)) {
        router.replace({
          path: '/home'
        })
      }
      next()
    }
  } else {
    next()
  }
})

export default router
