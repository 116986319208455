import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/style.css';
import './styles/style.scss'

import './utils/element.js'

Vue.prototype.$eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
